import styled from "styled-components"
import { SectionTitle } from "styles"

export const RecognitionSection = styled.div`
  padding: 90px 0;
  background-color: #11100f;
`

export const RecognitionTitle = styled(SectionTitle)`
  color: #fff;
  margin-bottom: 50px;
  text-align: left;
`

export const RecognitionDescription = styled.div`
  font-size: 22px;
  max-width: 500px;
  color: #fff;
  font-weight: 400;
  margin: -15px 0 7px 0;

  @media (max-width: 1024px) {
    font-size: 20px;
  }

  @media (max-width: 425px) {
    font-size: 18px;
  }
`

export const RecognitionGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 40px;
  margin-top: 40px;
`

export const RecognitionItem = styled.a`
  max-width: 250px;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 768px) {
    max-width: 200px;
  }

  &:hover {
    transform: scale(1.1);
  }

  img {
    width: 100%;
  }
`
