import React, { useEffect, useRef, useState } from "react"
import { animated } from "react-spring"
import { navigate } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import * as THREE from "three"
import BIRDS from "vanta/dist/vanta.birds.min"

import {
  Button,
  Cases,
  IndustriesNew,
  PromotionText,
  Services,
  TestimonialsNew,
} from "components"
import { Container } from "components/Layout/styled"
import Engagement from "./components/Engagement"
import Recognition from "./components/Recognition"
import Technologies from "./components/Technologies"
import { useAnimation } from "./hooks/useAnimation"
import {
  ClutchWidget,
  HighLight,
  Intro,
  Stack,
  Subtitle,
  SvgLine,
  Title,
  TitleCaption,
  TitleWrapper,
} from "./styled"

const HomePage = ({ data: { sections } }) => {
  const [vantaEffect, setVantaEffect] = useState(0)
  const [lineLength, setLineLength] = useState(null)
  const [arrowRightLength, setArrowRightLength] = useState(null)
  const [arrowLeftLength, setArrowLeftLength] = useState(null)
  const backgroundRef = useRef(null)
  const { animation } = useAnimation({
    lineLength,
    arrowRightLength,
    arrowLeftLength,
  })

  useEffect(() => {
    if (!vantaEffect && typeof window !== "undefined") {
      setVantaEffect(
        BIRDS({
          THREE: THREE,
          el: backgroundRef.current,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: window.innerHeight,
          minWidth: window.innerWidth,
          scale: 1.0,
          scaleMobile: 1.0,
          backgroundColor: 0x11100f,
          color1: 0x753a6e,
          color2: 0xb39bc8,
          birdSize: 1.5,
          quantity: 3,
          speedLimit: 4.0,
          forceAnimate: true,
        })
      )
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    // add widget to end of body and run it
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.src = "https://widget.clutch.co/static/js/widget.js"
    script.async = true
    document.body.appendChild(script)
    // run script
    script.onload = () => {
      window.CLUTCHCO.Init()
    }
    return () => {
      window.CLUTCHCO.Destroy()
      document.body.removeChild(script)
    }
  }, [])

  const handleContact = () => {
    navigate("/contact-us")
  }

  const renderSections = section => {
    const { name, id } = section

    switch (name) {
      case "Intro":
        return (
          <Intro key={id}>
            <div ref={backgroundRef}></div>
            <Container>
              <TitleWrapper>
                <animated.div
                  style={{ ...animation.captionStyles, position: "relative" }}
                >
                  <TitleCaption>{section.titleCaption}</TitleCaption>
                </animated.div>

                <SvgLine
                  style={{ zIndex: vantaEffect ? -1 : -10 }}
                  viewBox="0 0 63 106"
                  fill="none"
                >
                  <animated.path
                    style={animation.svgLineStyles}
                    d="M1.5 0.5C1.5 90.4998 55.5 10 55.5 103.5"
                    stroke="white"
                    strokeWidth="3"
                    ref={ref => {
                      if (ref) {
                        setLineLength(ref.getTotalLength())
                      }
                    }}
                  />
                  <animated.path
                    style={animation.svgArrowRightStyles}
                    d="M55 104.479L62 97"
                    stroke="white"
                    strokeWidth="2"
                    ref={ref => {
                      if (ref) {
                        setArrowRightLength(ref.getTotalLength())
                      }
                    }}
                  />
                  <animated.path
                    style={animation.svgArrowLeftStyles}
                    d="M48.3586 97L56 104"
                    stroke="white"
                    strokeWidth="2"
                    ref={ref => {
                      if (ref) {
                        setArrowLeftLength(ref.getTotalLength())
                      }
                    }}
                  />
                </SvgLine>
              </TitleWrapper>

              <animated.div
                style={{ ...animation.titleStyles, position: "relative" }}
              >
                <Title>
                  {section.title}
                  <HighLight
                    style={{
                      zIndex: vantaEffect ? -1 : -10,
                      position: "relative",
                    }}
                  >
                    {section.titleNeon.map((item, index) => (
                      <span key={index}>{item}</span>
                    ))}
                  </HighLight>
                </Title>
              </animated.div>

              <animated.div
                style={{ ...animation.subtitleStyles, position: "relative" }}
              >
                <Subtitle>{renderRichText(section.subtitle)}</Subtitle>

                <Stack>
                  <Button onClick={handleContact}>{section.btnText}</Button>

                  <ClutchWidget>
                    <div
                      className="clutch-widget"
                      data-url="https://widget.clutch.co"
                      data-widget-type="14"
                      data-height="50"
                      data-nofollow="true"
                      data-expandifr="true"
                      data-scale="100"
                      data-clutchcompany-id="2017906"
                    ></div>
                  </ClutchWidget>
                </Stack>
              </animated.div>
            </Container>
          </Intro>
        )
      case "Testimonials":
        return <TestimonialsNew data={section} key={id} />
      case "Promotion":
        return (
          <PromotionText
            text={renderRichText(section.text)}
            isSection={true}
            key={id}
          />
        )
      case "Services":
        return <Services services={section} key={id} page="home" />
      case "Cases":
        return <Cases cases={section} isSection={true} key={id} />
      case "Industries":
        return <IndustriesNew industry={section} key={id} />
      case "Engagement":
        return <Engagement engagement={section} key={id} />
      case "Recognition":
        return <Recognition recognition={section} key={id} />
      case "Technologies":
        return <Technologies technologies={section} key={id} />

      default:
        return null
    }
  }

  return <>{sections.map(section => renderSections(section))}</>
}

export default HomePage
