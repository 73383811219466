import React from "react"
import { graphql } from "gatsby"

import HomePage from "../containers/HomePage"

import { Seo } from "components"

const IndexPage = ({ data: { allContentfulPage } }) => {
  const pageData = allContentfulPage.nodes[0]

  return (
    <>
      <HomePage data={pageData} />
    </>
  )
}

export const Head = ({ location }) => (
  <Seo
    title="Nuevacode: IT Solutions and Digital Transformation Services"
    description="Nuevacode: Your partner in digital transformation. Specializing in innovative IT solutions, we empower businesses to achieve their objectives with cutting-edge technology and expert services. Discover how we can transform your business today."
    ogUrl={location.pathname}
  />
)

export const query = graphql`
  query {
    allContentfulPage(filter: { name: { eq: "Home page" } }) {
      nodes {
        id
        name
        sections {
          ... on ContentfulIntro {
            id
            name
            btnText
            subtitle {
              raw
            }
            title
            titleCaption
            titleNeon
          }

          ... on ContentfulTestimonials {
            id
            name
            sectionTitle
            testimonialsItems {
              reviewTitle
              reviewText {
                reviewText
              }
              image {
                file {
                  url
                }
              }
              reviewerName
              reviewerInfo
              projectCountry
              projectSummary {
                projectSummary
              }
              projectIndustry
              projectTeamSize
              projectDuration
              link
              linkText
              projectTechnologies
              id
            }
          }

          ... on ContentfulPromotion {
            id
            name
            text {
              raw
            }
          }

          ... on ContentfulServices {
            id
            name
            sectionTitle
            btnText
            serviceItems {
              img {
                file {
                  url
                }
              }
              id
              slug
              title
              desc {
                desc
              }
            }
            whyWeDoText {
              raw
            }
          }

          ... on ContentfulCases {
            id
            name
            title
            casesList {
              img {
                gatsbyImageData(aspectRatio: 1.36)
              }
              description
              id
              projectInfo {
                projectStatus
                technologies
              }
              slug
              title
            }
          }

          ... on ContentfulIndustry {
            id
            name
            sectionTitle
            industryItems {
              id
              title
              desc {
                desc
              }
            }
          }

          ... on ContentfulEngagement {
            id
            name
            sectionTitle
            engagementItems {
              id
              title
              img {
                file {
                  url
                }
              }
              desc
            }
          }

          ... on ContentfulRecognition {
            id
            name
            sectionTitle
            description
            clutchLink
            images {
              id
              gatsbyImageData
            }
          }

          ... on ContentfulTechnologies {
            id
            name
            sectionTitle
            technologies {
              id
              title
              img {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
